import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivityModel, LimitedResultOfActivityModel } from '@volt/api';
import { SharedTableColumn } from '@volt/shared/components/table/models/shared-table-column';
import { SharedTableState } from '@volt/shared/components/table/models/shared-table-state';
import { PermissionsService } from '@volt/shared/services/permissions.service';
import { ActivityFilterParams } from '../../models/activity-filter-params';
import { AuthService } from '../../../auth';

@Component({
  selector: 'activity-list',
  templateUrl: './activity-list.component.html',
  styleUrls: ['./activity-list.component.scss'],
})
export class ActivityListComponent implements OnInit {
  @Output() tableStateChange = new EventEmitter<SharedTableState>();

  @Input() activityData: LimitedResultOfActivityModel;
  @Input() areActivitiesLoading: boolean;

  columns: SharedTableColumn<ActivityModel>[];
  currentFilter = new ActivityFilterParams();

  constructor(private _permissionsService: PermissionsService, private _authService: AuthService) {}

  ngOnInit(): void {
    this.columns = [
      {
        field: 'banner',
        headerKey: 'banner',
        disabled: this._authService.getCurrentUser().banners.length < 2,
        width: '6em',
      },
      { field: 'activityName', headerKey: 'activities', sortable: true, width: '6em' },
      { field: 'note', headerKey: 'note', sortable: false, width: '25em' },
      { field: 'dueDatePerformance', headerKey: 'dueDatePerformance', sortable: true, width: '6em' },
      { field: 'scheduleImportant', headerKey: 'scheduleImportancePriority', sortable: true, width: '6em' },
      { field: 'companiesAssociatedCount', headerKey: 'companiesAssociated', sortable: true, width: '6em' },
      { field: 'statusName', headerKey: 'status', sortable: true, width: '6em' },
    ];
  }

  tableStateChanged(state: SharedTableState) {
    this.tableStateChange.emit(state);
  }
}
