import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanLoad,
  Route,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { BannerFeatureService } from '@volt/shared/services/banner-feature.service';
import { RedirectUserService } from '@volt/shared/services/redirect-user.service';
import { tap } from 'rxjs/operators';

export enum BannerFeature {
  supplierSignage
}

@Injectable({
  providedIn: 'root'
})
export class BannerGuard implements CanActivate, CanLoad {
  private readonly bannerDataKey = 'requiredBannerFeature';

  constructor(private readonly _bannerFeatureService: BannerFeatureService,
              private readonly _redirectUserService: RedirectUserService,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.hasBannerFeature(route.data[this.bannerDataKey]);
  }

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.hasBannerFeature(route.data[this.bannerDataKey]);
  }

  private hasBannerFeature(bannerFeature: BannerFeature): Observable<boolean> {
    return this._bannerFeatureService.bannerHasFeature$(bannerFeature).pipe(
      tap(hasBannerFeature => {
        if(!hasBannerFeature) {
          this._redirectUserService.redirectUnauthorizedUser();
        }
      })
    );
  }

}
