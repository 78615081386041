import { Injectable } from '@angular/core';
import { AuthService } from '../../auth';
import { BannerFeature } from '@volt/shared/guards/banner.guard';
import { map, take } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BannerFeatureService {

  constructor(private readonly _authService: AuthService) { }

  private readonly _bannerFeatures: Record<BannerFeature, string[]> = {
    '0': ['Walmart']
  };

  bannerHasFeature$(bannerFeature: BannerFeature): Observable<boolean> {
    const allowedBanners = this._bannerFeatures[bannerFeature];

    if(allowedBanners == null) {
      return of(true);
    }

    return this._authService.authUser$.pipe(
      map(user => {
        for(const banner of user.banners) {
          if(allowedBanners.includes(banner)) {
            return true;
          }
        }

        return false;
      }),
      take(1),
    )
  }
}
